body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, p, blockquote, th, td, button {
  margin: 0;
  padding: 0;
  border: 0;
}

.App {
  text-align: center;
}

.kfr-logo-err {
    height: 40vmin;
    pointer-events: none;
}

.kfr-btn-err {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header button {
  margin-top: 20px;
  font-size: calc(10px + 3vmin);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.kfr-copy-banner {
  position: absolute;
  background-color: #282c34;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.kfr-copy-banner > img {
  max-height: 10vh;
}

.kfr-copy-banner > p {
  max-width: 1000px;
  margin: 20px 5px;
}

.kfr-copy-confirm {
  width: 100%;
  text-align: right;
}

.kfr-copy-confirm > button {
  margin-right: 50px;
  font-size: calc(10px + 3vmin);
}

.kfr-dir {
    min-height: 100vh;
    background-color: #282c3409;
}

.kfr-dir-breadcrumbs {
  list-style: none;
  padding: 0;
  /* width: 100%; */
  min-height: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 20px;
  font-weight: bold;
  background-color: rgba(0,0,0,.07);
  white-space: nowrap;
}

.kfr-dir-breadcrumbs > li {
  display: inline-block;
  padding: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
}

.kfr-dir-breadcrumbs > li:after {
    padding-left: 5px;
    content: ' >'
}

.kfr-dir-breadcrumbs > li:last-child:after {
    content: none;
}

.kfr-dir-breadcrumbs > li > a {
    color: inherit;
    text-decoration: inherit;
}

.kfr-dir-listing {
    width: 100%;
}

.kfr-dir-listing > thead > tr > th, .kfr-dir-listing > tbody > tr > td {
    text-align: left;
    padding: 0 5px;
    word-break: break-word;
}

.kfr-dir-listing > thead > tr > th {
    min-width: 80px;
}

.kfr-dir-listing > thead > tr > th:nth-child(2) {
    width: 100%;
}

.kfr-dir-listing > tbody > tr {
    height: 30px;
    cursor: pointer;
}
.kfr-dir-listing > tbody > tr:nth-child(odd) {
    height: 30px;
    background-color: rgba(1,1,1,.07);
}

.kfr-dir-row-sel {
    background-color: rgba(0,0,0,.3) !important; /* important because we lazy */
}

.kfr-fullpic {
  height: 80vh;
  text-align: center;
}

.kfr-fullpic > * > * {
  max-width: 100%;
  max-height: 100%;
}

.kfr-thumb-bar {
  bottom: 0;
  width: 100%;
  height: 10vh;
  overflow: hidden;
  white-space: nowrap;
}

.kfr-thumb-bar > div {
  height: 100%;
  width: 200%;
}

.kfr-thumb-bar > div > a {
    height: 100%;
}

.kfr-thumb-bar-top > div {
  float: right;
}
.kfr-thumb-bar-top > div > * {
  float: right;
}
.kfr-thumb-bar-bottom > div {
  float: left;
}
.kfr-thumb-bar-bottom > div > * {
  float: left;
}

.kfr-thumb-bar > div > * {
  cursor: pointer;
}

.kfr-thumb-bar img {
  height: 100%;
}

.kfr-thumb-current {
  border: 3px solid #FF0000;
  height: 100%;
  cursor: default;
  display: inline-block;
}
